<template>
  <!-- 页面名称：我的课程
      开发人员：zqq -->
  <div class="content">
    <div class="adv-img">
      <!-- <img src="../../../assets/img/course.png" alt="课程" /> -->
      <div class="adv-txt">
        <div class="adv-system">
          <span class="system">课程体系</span>
          <span class="highOrder">UE5开发系列课程</span>
        </div>
        <p class="optimistic">
          UE5从基础学习，零基础从入门到精通，从小白到大神
        </p>
      </div>
    </div>
    <div class="loading" v-loading="loading">
      <div class="list-wrap">
        <div class="wrap-list" v-if="curriculum.length !== 0">
          <div
            class="list"
            v-for="(item, index) in curriculum"
            :key="index"
            @click="courseVideo(index)"
          >
            <div class="list-img">
              <!-- <img src="../../../assets/img/curriculum.png" /> -->
            </div>
            <p class="list-name">{{ item.crs_name }}</p>
            <div class="list-teacher">
              <img style="display: none" class="teacher-img" src="../../../assets/img/teacher.png" />
              <span class="lecturer">{{ item.instru_name }}</span>
              <div class="course-progress">
                <p class="rate">
                  <span>{{ item.study_rate }}</span
                  >%
                </p>
                <div class="schedule">
                  <div
                    class="progress"
                    :style="{ left: proportion(item.study_rate) }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <defaultPage :text="text"></defaultPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourse } from "../../../http/api/Course";
import defaultPage from "../data404.vue";
export default {
  components: {
    defaultPage,
  },
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },

  data() {
    //数据

    return {
      curriculum: [],
      loading: true,
      text: "抱歉 , 您暂时没有内容~~",
    };
  },

  methods: {
    //课程
    search() {
      getCourse().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.curriculum = res.data;
        } else {
          this.$message.error(res.error);
        }
        this.loading = false;
      });
    },
    //跳转视频页
    courseVideo(index) {
      // console.log(index);
      // console.log(this.curriculum[index]);
      this.$router.push({
        path: "/courseVideo",
        query: {
          id: this.curriculum[index].id,
          crs_name: this.curriculum[index].crs_name,
          instru_name: this.curriculum[index].instru_name,
        },
      });
    },
    //进度
    proportion(val) {
      var data = 100 - val;
      var proportion = "-" + data + "%";
      return proportion;
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 880px;
  padding-bottom: 50px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  .adv-img {
    width: 100%;
    height: 160px;
    background: url("../../../assets/img/course.png");
    background-size: cover;
    .adv-txt {
      position: absolute;
      top: 103px;
      left: 37%;
      .adv-system {
        .system {
          font-size: 27px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: center;
          color: #ffe164;
          margin-right: 31px;
        }
        .highOrder {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
        }
      }
      .optimistic {
        margin-top: 8px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
  .loading {
    width: 100%;
    min-height: 100px;
    .list-wrap {
      max-width: 68.4%;
      margin: 0 auto;
      min-height: 360px;
      .wrap-list {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* 折中 */
        @media screen and (min-width: 1025px) and (max-width: 1375px) {
          .list {
            width: calc((100% - 20px * 2) / 2);
          }
        }
        /* 折中 */
        @media screen and (min-width: 1375px) and (max-width: 1679px) {
          .list {
            width: calc((100% - 20px * 3) / 3);
          }
        }
      }
      .list {
        // list-style: none;
        /*width: 305px;*/
        height: 170px;
        margin-right: 20px;
        width: calc((100% - 20px * 4) / 4);
        background: #ffffff;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 6px;
        .list-img {
          width: 32px;
          height: 32px;
          margin-top: 24px;
          margin-left: 32px;
          background: #eff0f5;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          background: url("../../../assets/img/curriculum.png");
          background-size: 32px;
        }
        .list-name {
          width: 240px;
          height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 600;
          color: #00264d;
          margin-left: 32px;
          margin-top: 16px;
          margin-bottom: 0px;
        }
        .list-teacher {
          display: flex;
          margin-left: 32px;
          .teacher-img {
            width: 28px;
            height: 28px;
            margin-top: 24px;
            margin-right: 8px;
          }
          .lecturer {
            margin-top: 30px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #657489;
            margin-right: 16px;
          }
          .course-progress {
            margin-top: 18px;
            display: flex;
            align-items: center;
            .rate {
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Semibold;
              font-weight: 400;
              color: #0080ff;
              span {
                font-weight: 600;
              }
            }
            .schedule {
              width: 73px;
              height: 6px;
              border-radius: 3px;
              background: #eff0f5;
              overflow: hidden;
              margin-left: 4px;
              .progress {
                width: 73px;
                height: 6px;
                border-radius: 3px;
                background: #0080ff;
                position: relative;
                left: -100%;
                transition: 0.5s;
              }
            }
          }
        }
      }
      .list:hover {
        .list-img {
          transition: 0.25s;
          background: url("../../../assets/img/curriculum-active.png");
          background-size: 32px;
        }
      }
      .wrap-list:after {
        content: "";
        flex: auto;
      }
    }
  }
  .no-course {
    width: 100%;
    text-align: center;
    .pitera {
      font-size: 18px;
      color: #8a8a8a;
      margin-top: 170px;
    }
  }
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .content {
    padding-bottom: 0;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .adv-img {
      width: 100%;
      height: 160px;
      background: url("../../../assets/img/course.png");
      background-size: cover;

      .adv-txt {
        position: absolute;
        top: 75px;
        left: 0;

        .adv-system {
          padding: 0 10px;
          .system {
            font-size: 24px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            color: #ffe164;
            margin-right: 31px;
          }

          .highOrder {
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .optimistic {
          margin: 8px 10px 0 10px;
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.7;
        }
      }
    }
    .loading{
      .list-wrap {
        width: 100%;
        max-width: 100%;
        min-height: auto;
        .wrap-list {
          .list{
            width: 100%;
            margin: 0 20px 20px 20px;
          }
        }
      }
    }
  }
}
</style>
